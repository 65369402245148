var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "~/components/common/file-upload.vue";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { ImportExcelCaseService } from "~/services/dataimp-service/import-excel-case.service";
import { DataImportService } from "~/services/dataimp-service/data-import.service";
var ImportDivisionCase = /** @class */ (function (_super) {
    __extends(ImportDivisionCase, _super);
    function ImportDivisionCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.importModel = {
            fileId: '' //文件ID
        };
        _this.importRules = {
            fileId: { required: true, message: '请上传要分案的文件' },
        };
        _this.mfileList = [];
        return _this;
    }
    ImportDivisionCase.prototype.close = function () {
        this.reset();
    };
    ImportDivisionCase.prototype.success = function () {
        this.$message.success("操作成功");
        this.close();
    };
    Object.defineProperty(ImportDivisionCase.prototype, "fileList", {
        get: function () {
            return this.mfileList;
        },
        set: function (val) {
            this.mfileList = val;
            this.importModel.fileId = val.length && val[0].response ? val[0].response.id : "";
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 表单重置
     */
    ImportDivisionCase.prototype.reset = function () {
        var upload = this.$refs['upload_case_import'];
        upload.reset();
        var form = this.$refs['form'];
        form.resetFields();
    };
    /**
     * 表单提交
     */
    ImportDivisionCase.prototype.submit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.dataImportService.importDistribute(_this.importModel.fileId, _this.loading).subscribe(_this.success);
        });
    };
    __decorate([
        Dependencies(ImportExcelCaseService)
    ], ImportDivisionCase.prototype, "importExcelCaseService", void 0);
    __decorate([
        Dependencies(DataImportService)
    ], ImportDivisionCase.prototype, "dataImportService", void 0);
    __decorate([
        Emit('close')
    ], ImportDivisionCase.prototype, "close", null);
    __decorate([
        Emit('success')
    ], ImportDivisionCase.prototype, "success", null);
    ImportDivisionCase = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], ImportDivisionCase);
    return ImportDivisionCase;
}(Vue));
export default ImportDivisionCase;
