var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var DataImportService = /** @class */ (function (_super) {
    __extends(DataImportService, _super);
    function DataImportService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 导入案件结果查询
     */
    DataImportService.prototype.findAllDataImportResult = function (data, page, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findAllDataImportResult,
            data: data,
            page: page,
            loading: loading,
        });
    };
    /**
     * 案件导入
     */
    DataImportService.prototype.importCase = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.importCase,
            data: data,
            loading: loading,
        });
    };
    /**
     * 取消导入
     */
    DataImportService.prototype.cancelExcelImpCase = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelExcelImpCase,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 获取导入后的案件详情列表
     */
    DataImportService.prototype.getImportDataExcelCaseDetailList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.getImportDataExcelCaseDetailList,
            data: {
                batchNumber: data.batchNumber,
                name: data.name,
                certificateNo: data.certificateNo,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件导入确认
     */
    DataImportService.prototype.confirmCase = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmCase,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 待分配案件多条件查询
     */
    DataImportService.prototype.findDistributeCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findDistributeCase,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                caseNumber: data.caseNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                selfPhoneNo: data.selfPhoneNo,
                stage: data.stage,
                minOverdueAmtTotal: data.overdueAmtTotal.min,
                maxOverdueAmtTotal: data.overdueAmtTotal.max,
                minLeftAmt: data.leftAmt.min,
                maxLeftAmt: data.leftAmt.max,
                employerName: data.employerName,
                address: data.address,
                minDelegationDate: data.delegationDate[0],
                maxDelegationDate: data.delegationDate[1],
                minOverduePeriods: data.overduePeriods.min,
                maxOverduePeriods: data.overduePeriods.max,
                minOverdueDays: data.overdueDays.min,
                maxOverdueDays: data.overdueDays.max,
                cardNo: data.cardNo,
                city: data.city,
                minEndCaseDate: data.endCaseDate[0],
                maxEndCaseDate: data.endCaseDate[1],
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 结清案件查询
     */
    DataImportService.prototype.findSettleCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findSettleCase,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                caseNumber: data.caseNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                selfPhoneNo: data.phone,
                stage: data.stage,
                minOverdueAmtTotal: data.overdueAmtTotal.min,
                maxOverdueAmtTotal: data.overdueAmtTotal.max,
                minLeftAmt: data.leftAmt.min,
                maxLeftAmt: data.leftAmt.max,
                employerName: data.employerName,
                address: data.address,
                minDelegationDate: data.delegationDate[0],
                maxDelegationDate: data.delegationDate[1],
                minOverduePeriods: data.overduePeriods.min,
                maxOverduePeriods: data.overduePeriods.max,
                minOverdueDays: data.overdueDays.min,
                maxOverdueDays: data.overdueDays.max,
                cardNo: data.cardNo,
                city: data.city,
                minEndCaseDate: data.endCaseDate[0],
                maxEndCaseDate: data.endCaseDate[1],
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件更新导入
     */
    DataImportService.prototype.importCaseUpdate = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.importCaseUpdate,
            data: data,
            loading: loading,
        });
    };
    /**
     * 案件更新导入确认
     */
    DataImportService.prototype.confirmUpdateCase = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmUpdateCase,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 默认模板ID返回
     */
    DataImportService.prototype.getTemplateId = function (type, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.getTemplateId,
            data: {
                type: type,
            },
            loading: loading,
        });
    };
    /**
     * 对账单导入
     */
    DataImportService.prototype.importBill = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.importBill,
            data: data,
            loading: loading,
        });
    };
    /**
     * 对账单取消导入
     */
    DataImportService.prototype.cancelImportBill = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelImportBill,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 对账单详情查询
     */
    DataImportService.prototype.findImportBillByBatchNumber = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findImportBillByBatchNumber,
            data: {
                batchNumber: data.batchNumber,
                caseNumber: data.caseNumber,
                minPaymentDate: data.paymentDate[0],
                maxPaymentDate: data.paymentDate[1],
                minPaymentAmt: data.paymentAmt.min,
                maxPaymentAmt: data.paymentAmt.max,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 对账单导入确认
     */
    DataImportService.prototype.confirmImportBill = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmImportBill,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 对账单单个撤销
     */
    DataImportService.prototype.cancelBillImport = function (id, recordId, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelBillImport,
            data: {
                id: id,
                recordId: recordId,
            },
            loading: loading,
        });
    };
    /**
     * 对账单批量撤销
     */
    DataImportService.prototype.cancelBatchBillImport = function (batchNumber, recordId, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelBatchBillImport,
            data: {
                batchNumber: batchNumber,
                recordId: recordId,
            },
            loading: loading,
        });
    };
    /**
     * 委前调记导入
     */
    DataImportService.prototype.importPreRecord = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.importPreRecord,
            data: data,
            loading: loading,
        });
    };
    /**
     * 委前调记取消导入
     */
    DataImportService.prototype.cancelImportPreRecord = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelImportPreRecord,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 委前调记详情查询
     */
    DataImportService.prototype.findImportPreByBatchNumber = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findImportPreByBatchNumber,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 委前调记导入确认
     */
    DataImportService.prototype.confirmImportPreRecord = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmImportPreRecord,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 委前调记导入撤销
     */
    DataImportService.prototype.cancelBatchPreImport = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelBatchPreImport,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 工单导入
     */
    DataImportService.prototype.importWorkOrder = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.importWorkOrder,
            data: data,
            loading: loading,
        });
    };
    /**
     * 警告导入
     */
    DataImportService.prototype.importWarning = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.importWarning,
            data: data,
            loading: loading,
        });
    };
    /**
     * 工单详情查询
     */
    DataImportService.prototype.findImportWorkByBatchNumber = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findImportWorkByBatchNumber,
            data: {
                batchNumber: data.batchNumber,
                caseNumber: data.caseNumber,
                minDate: data.date[0],
                maxDate: data.date[1],
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 警告详情查询
     */
    DataImportService.prototype.findImportWarningByBatchNumber = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findImportWarningByBatchNumber,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 工单取消导入
     */
    DataImportService.prototype.cancelImportWorkOrder = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelImportWorkOrder,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 警告取消导入
     */
    DataImportService.prototype.cancelImportWarning = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelImportWarning,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 工单导入确认
     */
    DataImportService.prototype.confirmImportWorkOrder = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmImportWorkOrder,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 警告导入确认
     */
    DataImportService.prototype.confirmImportWarning = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmImportWarning,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 工单导入撤销
     */
    DataImportService.prototype.cancelBatchWorkImport = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelBatchWorkImport,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 警告导入撤销
     */
    DataImportService.prototype.cancelBatchWarningImport = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelBatchWarningImport,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 留案导入
     */
    DataImportService.prototype.leaveImport = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.leaveImport,
            data: data,
            loading: loading,
        });
    };
    /**
     * 停调导入
     */
    DataImportService.prototype.stopImport = function (data, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.stopImport,
            data: data,
            loading: loading,
        });
    };
    /**
     * 留案导入详情查询
     */
    DataImportService.prototype.findImportLeaveByBatchNumber = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findImportLeaveByBatchNumber,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 停调导入详情查询
     */
    DataImportService.prototype.findImportStopByBatchNumber = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findImportStopByBatchNumber,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 留案取消导入
     */
    DataImportService.prototype.cancelImportLeave = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelImportLeave,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 停调取消导入
     */
    DataImportService.prototype.cancelImportStop = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelImportStop,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 留案导入确认
     */
    DataImportService.prototype.confirmImportLeave = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmImportLeave,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 停调导入确认
     */
    DataImportService.prototype.confirmImportStop = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmImportStop,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 留案导入批量撤销
     */
    DataImportService.prototype.cancelBatchLeaveImport = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelBatchLeaveImport,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 停调导入批量撤销
     */
    DataImportService.prototype.cancelBatchStopImport = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelBatchStopImport,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 下载分案导入模板
     */
    DataImportService.prototype.downloadDistributeTemplate = function (loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.downloadDistributeTemplate,
            loading: loading,
        });
    };
    /**
     * 分案导入
     */
    DataImportService.prototype.importDistribute = function (fileId, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.importDistribute,
            data: {
                fileId: fileId,
            },
            loading: loading,
        });
    };
    /**
     * 分案导入确认
     */
    DataImportService.prototype.confirmImportDistribute = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.confirmImportDistribute,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 分案导入取消
     */
    DataImportService.prototype.cancelImportDistribute = function (id, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.cancelImportDistribute,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 导入详情
     */
    DataImportService.prototype.findImportDistributeByBatchNumber = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findImportDistributeByBatchNumber,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 分案导入结果查询
     */
    DataImportService.prototype.findAllDistributeRecord = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.dataImportController.findAllDistributeRecord,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], DataImportService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findAllDataImportResult", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "importCase", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelExcelImpCase", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "getImportDataExcelCaseDetailList", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmCase", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findDistributeCase", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findSettleCase", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "importCaseUpdate", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmUpdateCase", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "getTemplateId", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "importBill", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelImportBill", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findImportBillByBatchNumber", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmImportBill", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelBillImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelBatchBillImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "importPreRecord", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelImportPreRecord", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findImportPreByBatchNumber", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmImportPreRecord", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelBatchPreImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "importWorkOrder", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "importWarning", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findImportWorkByBatchNumber", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findImportWarningByBatchNumber", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelImportWorkOrder", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelImportWarning", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmImportWorkOrder", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmImportWarning", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelBatchWorkImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelBatchWarningImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "leaveImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "stopImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findImportLeaveByBatchNumber", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findImportStopByBatchNumber", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelImportLeave", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelImportStop", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmImportLeave", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmImportStop", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelBatchLeaveImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelBatchStopImport", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "downloadDistributeTemplate", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "importDistribute", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "confirmImportDistribute", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "cancelImportDistribute", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findImportDistributeByBatchNumber", null);
    __decorate([
        Debounce()
    ], DataImportService.prototype, "findAllDistributeRecord", null);
    return DataImportService;
}(Service));
export { DataImportService };
